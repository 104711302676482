var DeviceType;
(function (DeviceType) {
    DeviceType["Group"] = "Group";
    DeviceType["Light"] = "Light";
    DeviceType["Outlet"] = "Outlet";
    DeviceType["Scenery"] = "Scenery";
    DeviceType["Sensor"] = "Sensor";
    DeviceType["Connector"] = "Connector";
    DeviceType["Automation"] = "Automation";
    DeviceType["Media"] = "Media";
    DeviceType["AirPurifier"] = "AirPurifier";
    /** @deprecated use Sensor */
    DeviceType["Thermometer"] = "Thermometer";
})(DeviceType || (DeviceType = {}));
/**
 * Interface
 * 433MHz Pulse
 *
 * Device Class Connectors (implements 433MHz Pulse)
 * RaxaTellstick
 * Sleipner433
 *
 * Device Class Lamps (requires 433MHz Pulse)
 * Nexa Selflearning
 * Nexa Switch Case
 *
 *
 */

export { DeviceType };
