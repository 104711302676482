import { c as curry2, a as autoCurry } from '../common/utils-034088c8.js';

/**
 * Either an Iterable (for example an Array) or an Iterator
 * (for example the result of a generator function)
 */

/** @internal */
const isIterator = iterator => {
  return typeof iterator[Symbol.iterator] === 'undefined';
};
/**
 * @internal
 */

const asIterable = iterator => {
  if (isIterator(iterator)) {
    return {
      [Symbol.iterator]: () => iterator
    };
  } else {
    return iterator;
  }
};
/**
 * Creates an iterator with values from `start` to `end`.
 *
 * If end is undefined, the iterator will have an infinite length.
 * If end is equal to start, no value will be emitted.
 *
 * The iterator will step with the specified `step` size which defaults to `1`.
 * The step size can be set to negative if the start value is higher than the end value
 *
 * The iterator will end with a return value of the value following the end value.
 *
 * ## Examples
 * ```typescript
 * [...range({start: 0, end: 3})] // [0, 1, 2]
 * [...range({start: 3, end: 2, step: -1})] // [2, 1, 0]
 * [...range({start: 0})] // (0, 1, 2, 3, 4, ...)
 * [...range({start: 0, step: -2})] // (0, -2, -4, -6, -8, ...)
 * ```
 */

function* range({
  start,
  end,
  step = 1
}) {
  if (start === end) return start;
  let value = start;

  while (true) {
    yield value;
    value += step;

    if (end !== undefined) {
      if (start < end) {
        if (value >= end) return value;
      } else {
        if (value <= end) return value;
      }
    }
  }
}
/**
 * Calls fn for every item in the iterator to produce an iterator
 * with the results of fn(item)
 *
 * ## Example
 * ```typescript
 * [...map(e => e*e), [1, 2, 3]] // [1, 4, 9]
 * ```
 */

const map = curry2(function* map(fn, iterator) {
  for (const item of asIterable(iterator)) {
    yield fn(item);
  }
});
/**
 * Like map but flattens the result a single level
 *
 * ## Example
 * ```typescript
 * [...flatMap(e => [e, e*e]), [1, 2, 3]] // [1, 1, 2, 4, 3, 9]
 * ```
 */

const flatMap = curry2(function* flatMap(fn, iterator) {
  for (const item of asIterable(iterator)) {
    for (const innerItem of asIterable(fn(item))) {
      yield innerItem;
    }
  }
});
/**
 * Calls predicate for every item in the iterator to produce an iterator
 * with only the items for which predicate returns true
 *
 * ## Example
 * ```typescript
 * [...filter(e => e > 2, [1, 2, 3])] // [2, 3]
 * ```
 */

const filter = curry2(function* filter(predicate, iterator) {
  for (const item of asIterable(iterator)) {
    if (predicate(item)) {
      yield item;
    }
  }
});
/**
 * Reduces an iterator to a single value by iteratively combining each
 * item of the iterator with an existing value
 *
 * Uses initialValue as the initial value, then iterates through the elements
 * and updates the value with each element using the combine function.
 *
 * If the iterator is empty, the initialValue is returned.
 *
 * ## Example
 * ```typescript
 * fold(0, (sum, item) => sum + item, [1, 2, 3]) // 6
 * ```
 */

const fold = autoCurry(function fold(initialValue, combine, iterator) {
  let value = initialValue;

  for (const item of asIterable(iterator)) {
    value = combine(value, item);
  }

  return value;
});
/**
 * Like fold, scan iteratively combines each item of the iterator with
 * an existing value. Scan does however yield each intermediate value.
 *
 * If the iterator is empty, no value is yielded.
 *
 * ## Example
 * ```typescript
 * scan(0, (sum, item) => sum + item, [1, 2, 3]) // [1, 3, 6]
 * ```
 */

const scan = autoCurry(function* scan(initialValue, combine, iterator) {
  let value = initialValue;

  for (const item of asIterable(iterator)) {
    value = combine(value, item);
    yield value;
  }

  return value;
});
/**
 * Returns the first value of the iterator or undefined if it's empty
 *
 * ## Example
 * ```typescript
 * first([1, 2, 3]) // 1
 * ```
 */

function first(iterator) {
  for (const item of asIterable(iterator)) {
    return item;
  }
}
const sort = curry2(function sort(fn, iterator) {
  const items = [...asIterable(iterator)];
  items.sort(fn);
  return items;
});

export { filter, first, flatMap, fold, map, range, sort };
