const mediaItem = {
    title: {
        id: 'title',
        type: 'string',
        name: 'Title',
    },
    artwork: {
        id: 'artwork',
        type: 'string',
        name: 'Artwork',
    },
    duration: {
        id: 'duration',
        type: 'number',
        name: 'Duration',
    },
    artist: {
        id: 'artist',
        type: 'string',
        name: 'Artist',
    },
    album: {
        id: 'album',
        type: 'string',
        name: 'Album',
    },
};
const defaultInterfaces = {
    Trigger: {
        id: 'Trigger',
        events: {
            triggered: {
                id: 'triggered',
                type: 'object',
                properties: {
                    pluginId: { id: 'pluginId', type: 'string' },
                    triggerId: { id: 'triggerId', type: 'string' },
                },
            },
        },
    },
    Power: {
        id: 'Power',
        status: {
            on: {
                id: 'on',
                type: 'boolean',
                uiTypeHint: 'switch',
                modifiable: true,
                defaultValue: false,
                interfaceId: 'Power',
                name: 'Power',
            },
        },
    },
    Dimmer: {
        id: 'Dimmer',
        status: {
            level: {
                id: 'level',
                type: 'integer',
                modifiable: true,
                max: 100,
                min: 0,
                defaultValue: 0,
                unit: '%',
                interfaceId: 'Dimmer',
                name: 'Brightness',
            },
        },
    },
    Color: {
        id: 'Color',
        name: 'RGB Color (deprecated)',
        status: {
            color: {
                id: 'color',
                type: 'integer',
                modifiable: true,
                defaultValue: 0,
                min: 0,
                max: 0xffffff,
                interfaceId: 'Color',
                name: 'Color',
            },
        },
    },
    HueSat: {
        id: 'HueSat',
        name: 'Color',
        status: {
            color: {
                id: 'color',
                name: 'Color',
                type: 'object',
                interfaceId: 'HueSat',
                optional: true,
                properties: {
                    hue: {
                        id: 'hue',
                        type: 'integer',
                        modifiable: true,
                        min: 0,
                        max: 359,
                        name: 'Hue',
                    },
                    saturation: {
                        id: 'saturation',
                        type: 'integer',
                        modifiable: true,
                        min: 0,
                        max: 100,
                        unit: '%',
                        name: 'Saturation',
                    },
                },
            },
        },
    },
    ColorTemperature: {
        id: 'ColorTemperature',
        name: 'Color Temperature',
        status: {
            temperature: {
                id: 'temperature',
                type: 'integer',
                modifiable: true,
                optional: true,
                min: 2000,
                max: 6500,
                unit: 'K',
                interfaceId: 'ColorTemperature',
                name: 'Color Temperature',
            },
        },
    },
    Temperature: {
        id: 'Temperature',
        status: {
            temp: {
                id: 'temp',
                type: 'number',
                unit: '°C',
                interfaceId: 'Temperature',
                name: 'Temperature',
            },
        },
    },
    RelativeHumidity: {
        id: 'RelativeHumidity',
        status: {
            relativeHumidity: {
                id: 'relativeHumidity',
                type: 'number',
                unit: '%',
                interfaceId: 'RelativeHumidity',
                name: 'Humidity',
            },
        },
    },
    AirQualityIndex: {
        id: 'AirQualityIndex',
        status: {
            aqi: {
                id: 'aqi',
                type: 'number',
                interfaceId: 'AirQualityIndex',
                name: 'Air Quality',
            },
        },
    },
    OnlineStatus: {
        id: 'OnlineStatus',
        status: {
            isAvalible: {
                id: 'isAvalible',
                type: 'boolean',
                interfaceId: 'OnlineStatus',
                name: 'Is avalible',
            },
            lastSeen: {
                id: 'lastSeen',
                type: 'string',
                uiTypeHint: 'isoDate',
                interfaceId: 'OnlineStatus',
                name: 'Last seen',
            },
        },
    },
    BatteryLevel: {
        id: 'BatteryLevel',
        status: {
            temp: {
                id: 'batteryLevel',
                type: 'number',
                min: 0,
                max: 100,
                unit: '%',
                interfaceId: 'BatteryLevel',
                name: 'Battery level',
            },
        },
    },
    '433MHzPulse': {
        id: '433MHzPulse',
        name: '433MHzPulse',
        methods: {
            send: {
                id: 'send',
                arguments: {
                    pulse: {
                        id: 'pulse',
                        type: 'array',
                        items: {
                            id: 'items',
                            type: 'integer',
                            minimum: 0,
                            maximum: 255,
                        },
                    },
                    repeats: {
                        id: 'repeats',
                        type: 'integer',
                        minimum: 1,
                    },
                    pause: {
                        id: 'pause',
                        type: 'integer',
                        minimum: 0,
                    },
                },
            },
        },
    },
    SelfLearning: {
        id: 'SelfLearning',
        name: 'Self Learning',
        methods: {
            learn: {
                id: 'learn',
                showInSettings: true,
                arguments: {},
            },
        },
    },
    CurrentlyPlaying: {
        id: 'CurrentlyPlaying',
        status: {
            currentMedia: {
                id: 'currentMedia',
                type: 'object',
                properties: mediaItem,
                name: 'Current Media',
            },
            playerState: {
                id: 'playerState',
                type: 'enum',
                values: [
                    { name: 'idle', value: 'idle' },
                    { name: 'playing', value: 'playing' },
                    { name: 'paused', value: 'paused' },
                    { name: 'buffering', value: 'buffering' },
                ],
                name: 'Player State',
            },
        },
        methods: {
            play: {
                id: 'play',
                showInSettings: true,
                arguments: {},
            },
            pause: {
                id: 'pause',
                showInSettings: true,
                arguments: {},
            },
            stop: {
                id: 'stop',
                showInSettings: true,
                arguments: {},
            },
        },
        events: {},
    },
    Volume: {
        id: 'Volume',
        status: {
            volume: {
                id: 'volume',
                type: 'number',
                min: 0,
                max: 1,
                modifiable: true,
                name: 'Volume',
            },
        },
        methods: {},
        events: {},
    },
    Mute: {
        id: 'Mute',
        status: {
            muted: {
                id: 'muted',
                type: 'boolean',
                modifiable: true,
                name: 'Muted',
            },
        },
        methods: {},
        events: {},
    },
    MediaPlaylist: {
        id: 'MediaPlaylist',
        status: {},
        methods: {
            next: {
                id: 'next',
                showInSettings: true,
                arguments: {},
            },
            previous: {
                id: 'previous',
                showInSettings: true,
                arguments: {},
            },
        },
        events: {},
    },
};

export { defaultInterfaces };
