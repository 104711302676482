import { r as react } from './index-7c2bbddc.js';

/**
 * @ignore - internal component.
 */

var FormControlContext = react.createContext();

function useFormControl() {
  return react.useContext(FormControlContext);
}

export { FormControlContext as F, useFormControl as u };
