import {gql, useQuery} from "../../../../web_modules/@apollo/client.js";
import IconButton2 from "../../../../web_modules/@material-ui/core/IconButton.js";
import ListSubheader2 from "../../../../web_modules/@material-ui/core/ListSubheader.js";
import DoneIcon from "../../../../web_modules/@material-ui/icons/Done.js";
import {pipeValue} from "../../../../web_modules/iterates.js";
import {fold, map, range} from "../../../../web_modules/iterates/sync.js";
import {shadow} from "../../../../web_modules/material-definitions.js";
import {defaultInterfaces} from "../../../../web_modules/raxa-common/lib/default-interfaces.js";
import {DeviceType} from "../../../../web_modules/raxa-common/lib/entities.js";
import React, {
  cloneElement,
  useEffect,
  useMemo,
  useState
} from "../../../../web_modules/react.js";
import styled2 from "../../../../web_modules/@emotion/styled.js";
import {
  LazyDragDropContext,
  LazyDraggable,
  LazyDroppable,
  reorder
} from "../../ui/lazy-loading.js";
import {ButtonWidget} from "./button.js";
import {CurrentlyPlayingWidget} from "./currently-playing.js";
import {DisplayWidget} from "./display.js";
import {LightWidget} from "./light.js";
class Hideable extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {isHidden: false};
    this.hideComponent = (isHidden) => {
      if (this.state.isHidden !== isHidden) {
        this.setState({isHidden});
      }
      return null;
    };
  }
  render() {
    return this.props.children(this.hideComponent, this.state.isHidden);
  }
}
function sortByOrder(sortOrder, dataArray) {
  const data = new Map();
  for (const item of dataArray) {
    data.set(item.id, item);
  }
  const out = [];
  for (const id of sortOrder) {
    if (data.has(id)) {
      out.push(data.get(id));
      data.delete(id);
    }
  }
  return [...out, ...data.values()];
}
const Container = styled2("div")(({row}) => ({
  display: "flex",
  flexDirection: row ? "row" : "column",
  flexWrap: row ? "wrap" : "nowrap",
  alignItems: "flex-start",
  justifyContent: "flex-start",
  marginTop: row ? -8 : 0,
  marginLeft: row ? 8 : 16,
  marginRight: row ? 8 : 16,
  marginBottom: row ? 8 : 16,
  boxShadow: row ? "none" : shadow[1].boxShadow
}));
const ListHeader = styled2(ListSubheader2)(({theme: theme2}) => ({
  "&&": {
    display: "flex",
    zIndex: 2,
    backgroundColor: theme2.dark ? theme2.background.main : theme2.background.light
  }
}));
const dragContainerStyles = (row) => ({
  flexGrow: row ? 1 : void 0,
  flexBasis: row ? "25%" : void 0,
  width: row ? void 0 : "100%"
});
const DeviceWrapperContainer = styled2("div")(({row, border, theme: theme2}) => ({
  position: "relative",
  flexShrink: 0,
  boxSizing: "border-box",
  margin: row ? 8 : 0,
  overflow: "hidden",
  backgroundColor: theme2.dark ? theme2.background.light : theme2.background.main,
  boxShadow: row ? shadow[1].boxShadow : "none",
  borderTop: !border || row ? "none" : "1px solid rgba(0, 0, 0, 0.12)"
}));
const DeviceWrapper = ({
  children,
  style,
  inEditMode,
  isHidden,
  ...props
}) => /* @__PURE__ */ React.createElement(DeviceWrapperContainer, {
  ...props,
  style: {
    ...style
  }
}, children, inEditMode && /* @__PURE__ */ React.createElement("div", {
  style: {
    position: "absolute",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    backgroundColor: `rgba(${isHidden ? "150, 150, 150" : "255, 255, 255"}, 0.7)`,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1
  }
}));
let hiddenDevices = new Map();
const getDevices = gql`
  query($types: [String!], $interfaceIds: [String!]) {
    devices(types: $types, interfaceIds: $interfaceIds) {
      id
      name
      types
      config
      interfaceIds
      status(interfaceIds: $interfaceIds) {
        id
        interfaceId
        statusId
        value
      }
    }
  }
`;
const displayStatuses = {
  [defaultInterfaces.AirQualityIndex.id]: [
    defaultInterfaces.AirQualityIndex.status.aqi.id
  ],
  [defaultInterfaces.Temperature.id]: [
    defaultInterfaces.Temperature.status.temp.id
  ],
  [defaultInterfaces.RelativeHumidity.id]: [
    defaultInterfaces.RelativeHumidity.status.relativeHumidity.id
  ]
};
export const ListWidget = ({
  variant,
  header,
  big,
  types,
  interfaceIds,
  setConfiguration,
  ...props
}) => {
  const configuration = useMemo(() => Array.isArray(props.configuration) ? {sortOrder: props.configuration, hidden: []} : props.configuration || {sortOrder: [], hidden: []}, [props.configuration]);
  const [inEditMode, setEditMode] = useState(false);
  const query = useQuery(getDevices, {
    fetchPolicy: "cache-and-network",
    variables: {
      types,
      interfaceIds
    }
  });
  const devices = useMemo(() => query.data && sortByOrder(configuration.sortOrder, query.data.devices), [query.data, configuration.sortOrder]);
  const onSortEnd = (result) => {
    if (!result.destination || !devices) {
      return;
    }
    const sorted = reorder(devices, result.source.index, result.destination.index);
    setConfiguration({sortOrder: sorted.map((d) => d.id)});
  };
  useEffect(() => {
    const listener = () => {
      if (!document.hidden)
        query.refetch();
    };
    document.addEventListener("visibilitychange", listener);
    return () => document.removeEventListener("visibilitychange", listener);
  }, [query]);
  return devices && devices.length > 0 ? /* @__PURE__ */ React.createElement("div", null, /* @__PURE__ */ React.createElement(LazyDragDropContext, {
    onDragEnd: onSortEnd
  }, header && /* @__PURE__ */ React.createElement(ListHeader, {
    color: "default",
    onContextMenu: (e) => {
      if (!e.ctrlKey) {
        e.preventDefault();
        setEditMode(true);
      }
    }
  }, /* @__PURE__ */ React.createElement("span", {
    style: {flex: 1}
  }, header), inEditMode && /* @__PURE__ */ React.createElement(IconButton2, {
    onClick: () => setEditMode(false)
  }, /* @__PURE__ */ React.createElement(DoneIcon, null))), /* @__PURE__ */ React.createElement(LazyDroppable, {
    droppableId: `droppable-${header}`,
    direction: variant === "row" ? "horizontal" : "vertical"
  }, (provided) => /* @__PURE__ */ React.createElement(Container, {
    ...provided.droppableProps,
    ref: provided.innerRef,
    row: variant === "row"
  }, devices.filter((device) => inEditMode || !configuration.hidden.includes(device.id)).map((device) => device.interfaceIds ? (device.types.includes(DeviceType.Sensor) || device.types.includes(DeviceType.AirPurifier) || device.types.includes(DeviceType.Thermometer)) && (!interfaceIds || interfaceIds.includes(defaultInterfaces.Temperature.id) || interfaceIds.includes(defaultInterfaces.RelativeHumidity.id) || interfaceIds.includes(defaultInterfaces.AirQualityIndex.id)) ? [
    false,
    device,
    /* @__PURE__ */ React.createElement(DisplayWidget, {
      deviceId: device.id,
      statuses: displayStatuses
    })
  ] : device.interfaceIds.includes("Scenery") && (!interfaceIds || interfaceIds.includes("Scenery")) ? [
    true,
    device,
    /* @__PURE__ */ React.createElement(ButtonWidget, {
      deviceId: device.id,
      interfaceId: "Scenery",
      method: "set"
    })
  ] : device.interfaceIds.includes("CurrentlyPlaying") && (!interfaceIds || interfaceIds.includes("CurrentlyPlaying")) ? [
    false,
    device,
    /* @__PURE__ */ React.createElement(CurrentlyPlayingWidget, {
      deviceId: device.id
    })
  ] : (device.types.includes(DeviceType.Light) || device.types.includes(DeviceType.Outlet)) && (!interfaceIds || interfaceIds.includes(defaultInterfaces.Power.id) || interfaceIds.includes(defaultInterfaces.Dimmer.id)) ? [true, device, /* @__PURE__ */ React.createElement(LightWidget, {
    deviceId: device.id
  })] : null : null).filter((innerWidget) => innerWidget != null).map(([disableInteractiveElementBlocking, device, innerWidget], i, collection) => /* @__PURE__ */ React.createElement(Hideable, {
    key: device.id
  }, (hideComponent, isHidden) => {
    hiddenDevices.set(`${header}::${device.id}`, isHidden);
    const visibleItemAbove = i > 0 ? pipeValue(range({start: i - 1, end: -1, step: -1}), map((i2) => hiddenDevices.get(`${header}::${collection[i2][1].id}`)), fold(false, (prevWasVisible, isHidden2) => prevWasVisible || !isHidden2)) : false;
    return isHidden ? cloneElement(innerWidget, {
      hideComponent
    }) : /* @__PURE__ */ React.createElement(LazyDraggable, {
      draggableId: device.id,
      index: i,
      isDragDisabled: !inEditMode,
      disableInteractiveElementBlocking
    }, (provided2) => /* @__PURE__ */ React.createElement("div", {
      ref: provided2.innerRef,
      ...provided2.draggableProps,
      ...provided2.dragHandleProps,
      style: {
        ...dragContainerStyles(variant === "row"),
        ...provided2.draggableProps.style
      },
      onClick: inEditMode ? (e) => {
        e.stopPropagation();
        setConfiguration({
          hidden: configuration.hidden.includes(device.id) ? configuration.hidden.filter((d) => d !== device.id) : [
            ...configuration.hidden,
            device.id
          ]
        });
      } : void 0
    }, /* @__PURE__ */ React.createElement(DeviceWrapper, {
      row: variant === "row",
      big,
      border: visibleItemAbove,
      inEditMode,
      isHidden: inEditMode && configuration.hidden.includes(device.id)
    }, cloneElement(innerWidget, {hideComponent}))));
  })))))) : null;
};
