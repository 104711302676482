/**
 * @internal
 */
/**
 * @internal
 */


/**
 * @internal
 */
const curry2 = func => (a, b) => {
  if (b === undefined) {
    return b => func(a, b);
  } else {
    return func(a, b);
  }
};
/**
 * @internal
 */

function autoCurry(fn) {
  const args = Array.from(arguments);
  if (fn.length <= 1) return fn;
  if (args.length - 1 >= fn.length) return fn.apply(this, args.slice(1));
  return function () {
    return autoCurry.apply(this, args.concat(Array.from(arguments)));
  };
}
function pipeValue(value, ...functions) {
  return functions.reduce((value, func) => func(value), value);
}

/**
 * An identity function that is typed so that Typescript understands it passes through a tuple.
 *
 * ## Example
 * ```typescript
 * const isArray = ['hello', 42] // isArray has type Array<string|number>
 * const isTuple = tuple(['hello', 42]) // isTuple has type [string, number]
 * ```
 */
function tuple(items) {
  return items;
}

export { autoCurry as a, curry2 as c, pipeValue as p, tuple as t };
