import AppBar2 from "../../../web_modules/@material-ui/core/AppBar.js";
import IconButton2 from "../../../web_modules/@material-ui/core/IconButton.js";
import {useTheme} from "../../../web_modules/@material-ui/core/styles.js";
import Toolbar2 from "../../../web_modules/@material-ui/core/Toolbar.js";
import Typography2 from "../../../web_modules/@material-ui/core/Typography.js";
import BackIcon from "../../../web_modules/@material-ui/icons/ArrowBack.js";
import {Link} from "../../../web_modules/@reach/router.js";
import React, {createContext, useContext, useEffect} from "../../../web_modules/react.js";
import {WithMedia} from "../../../web_modules/react-with-media.js";
import {column, flex, row} from "../../../web_modules/style-definitions.js";
import {Actions} from "./ContextActions.js";
export const scaffoldContext = createContext(void 0);
export const Section = (props) => {
  const context = useContext(scaffoldContext);
  useEffect(() => {
    context.setSection(props);
    return () => context.removeSection(props);
  }, [props.title, props.backTo, props.appBar, props.onUnload]);
  useEffect(() => {
    if (!context.activeSection) {
      context.setSection(props);
    }
  }, [!!context.activeSection]);
  return null;
};
const Container = (props) => /* @__PURE__ */ React.createElement("div", {
  ...props,
  style: {...row({flex: 1}), position: "relative"}
});
const ContentContainer = (props) => /* @__PURE__ */ React.createElement("div", {
  ...props,
  style: column({flex: 1})
});
export class Scaffold extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      sections: [],
      notifications: []
    };
    this.historyIndex = 0;
    this.updateContext = (setState = true) => {
      this.childContext = {
        activeSection: this.getActiveSection(),
        activeAppBar: this.getActiveAppBar(),
        setSection: this.setSection,
        removeSection: this.removeSection,
        setContextActions: (contextActions) => {
          this.setState({contextActions});
        },
        clearContextActions: () => {
          if (this.state.contextActions)
            this.setState({contextActions: void 0});
        }
      };
      if (setState) {
        this.setState({});
      }
    };
    this.setSection = (section) => {
      this.state.sections.unshift(section);
      this.updateContext();
    };
    this.removeSection = (section) => {
      this.state.sections = this.state.sections.filter((s) => s.title !== section.title);
      this.updateContext();
    };
    this.updateContext(false);
  }
  getActiveSection() {
    return this.state.sections[0];
  }
  getActiveAppBar() {
    const activeSection = this.getActiveSection();
    if (activeSection?.appBar === false)
      return false;
    const {
      position: sectionPosition = "static",
      elevated: sectionElevated = true
    } = activeSection?.appBar ?? {};
    return {position: sectionPosition, elevated: sectionElevated};
  }
  render() {
    const {appName, children} = this.props;
    const {contextActions} = this.state;
    const activeSection = this.getActiveSection();
    const backTo = activeSection?.backTo;
    const appBar = this.getActiveAppBar();
    return /* @__PURE__ */ React.createElement(scaffoldContext.Provider, {
      value: this.childContext
    }, /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(ContentContainer, null, appBar && /* @__PURE__ */ React.createElement(AppBar2, {
      color: "primary",
      position: appBar.position,
      elevation: appBar.elevated ? 4 : 0
    }, /* @__PURE__ */ React.createElement(Toolbar2, null, backTo && /* @__PURE__ */ React.createElement(Link, {
      to: backTo,
      replace: true,
      style: {color: "inherit"}
    }, /* @__PURE__ */ React.createElement(IconButton2, {
      "aria-label": "Back",
      color: "inherit"
    }, /* @__PURE__ */ React.createElement(BackIcon, null))), /* @__PURE__ */ React.createElement(Typography2, {
      variant: "h6",
      color: "inherit",
      style: flex(true)
    }, activeSection ? activeSection.title : appName), contextActions && /* @__PURE__ */ React.createElement(Actions, {
      actions: contextActions,
      color: "inherit"
    }))), /* @__PURE__ */ React.createElement("div", {
      style: {flex: 1}
    }, children))));
  }
}
export const ScaffoldAware = ({
  children
}) => {
  const theme = useTheme();
  const context = useContext(scaffoldContext);
  return children ? context.activeAppBar !== false && context.activeAppBar.position === "sticky" ? Object.keys(theme.mixins.toolbar).filter((key) => key.startsWith("@media")).reduce((inner, mediaQuery) => /* @__PURE__ */ React.createElement(WithMedia, {
    query: mediaQuery.slice("@media".length)
  }, (matches) => matches ? children({
    appBarHeight: theme.mixins.toolbar[mediaQuery].minHeight
  }) : inner ?? children({
    appBarHeight: theme.mixins.toolbar.minHeight
  })), null) : children({appBarHeight: 0}) : null;
};
