import {clone, updateIn} from "../../web_modules/react-form-helper/lib/helpers.js";
export function removeIn(path, object) {
  return removeInAny(path, object);
}
export function removeInAny(path, object) {
  if (arguments.length === 1) {
    return (object2) => removeInAny(path, object2);
  }
  if (Array.isArray(path) && path.length > 1) {
    const newValue = removeInAny(path.slice(1), (object || {})[path[0]]);
    return updateIn(path[0], newValue, object);
  }
  const key = Array.isArray(path) ? path[0] : path;
  const cloned = clone(object);
  if (Array.isArray(cloned)) {
    cloned.splice(key, 1);
  } else {
    delete cloned[key];
  }
  return cloned;
}
export function statusAsMap(status) {
  const interfaces = new Map();
  for (const element of status) {
    if (!interfaces.has(element.interfaceId)) {
      interfaces.set(element.interfaceId, new Map());
    }
    const iface = interfaces.get(element.interfaceId);
    iface.set(element.statusId, element.value);
  }
  return interfaces;
}
