import {gql, useQuery} from "../../../../web_modules/@apollo/client.js";
import Icon2 from "../../../../web_modules/@material-ui/core/Icon.js";
import IconButton2 from "../../../../web_modules/@material-ui/core/IconButton.js";
import Typography2 from "../../../../web_modules/@material-ui/core/Typography.js";
import PauseIcon from "../../../../web_modules/@material-ui/icons/Pause.js";
import PlayArrowIcon from "../../../../web_modules/@material-ui/icons/PlayArrow.js";
import {pipeValue} from "../../../../web_modules/iterates.js";
import {
  defaultInterfaces
} from "../../../../web_modules/raxa-common/lib/default-interfaces.js";
import React, {Suspense, lazy, useEffect, useState} from "../../../../web_modules/react.js";
import styled2 from "../../../../web_modules/@emotion/styled.js";
import {column, row} from "../../../../web_modules/style-definitions.js";
import {useCallDevice} from "../../../lib/mutations.js";
import {statusAsMap} from "../../../lib/object.js";
import {Option} from "../../../lib/types.js";
const LazyMediaDialog = lazy(() => import("./ui/media-dialog.js").then((module) => ({default: module.MediaDialog})));
const Container = styled2("div")({
  display: "flex",
  alignItems: "center",
  boxSizing: "border-box",
  height: 96,
  overflow: "hidden"
});
const getDevice = gql`
  query($deviceId: String!) {
    device(id: $deviceId) {
      id
      name
      status(interfaceIds: ["CurrentlyPlaying", "Volume"]) {
        id
        interfaceId
        statusId
        value
      }
    }
  }
`;
export const CurrentlyPlayingWidget = ({
  deviceId,
  hideComponent
}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const query = useQuery(getDevice, {
    skip: !deviceId,
    variables: {deviceId}
  });
  const device = query.data && query.data.device;
  const status = device && statusAsMap(device.status);
  const currentlyPlaying = pipeValue(status, Option.andThen((status2) => status2.get(defaultInterfaces.CurrentlyPlaying.id)), Option.andThen((currentlyPlaying2) => {
    const playerState = currentlyPlaying2.get(defaultInterfaces.CurrentlyPlaying.status.playerState.id);
    const currentMedia = currentlyPlaying2.get(defaultInterfaces.CurrentlyPlaying.status.currentMedia.id);
    if (playerState && currentMedia && playerState !== "idle") {
      return {playerState, currentMedia};
    }
  }));
  const callDevice = useCallDevice();
  const call = (interfaceId, method) => callDevice({
    deviceId,
    interfaceId,
    method,
    arguments: void 0
  });
  useEffect(() => {
    if (!(device && currentlyPlaying) && hideComponent) {
      hideComponent(true);
    }
  }, [device, currentlyPlaying, hideComponent]);
  return device && currentlyPlaying ? /* @__PURE__ */ React.createElement(Container, {
    onClick: () => setDialogOpen(true)
  }, hideComponent ? hideComponent(false) : null, currentlyPlaying.currentMedia.artwork && /* @__PURE__ */ React.createElement("img", {
    src: currentlyPlaying.currentMedia.artwork,
    style: {width: 96, height: 96, objectFit: "cover"},
    alt: ""
  }), /* @__PURE__ */ React.createElement("div", {
    style: {...column(), flex: 1, paddingLeft: 16, overflow: "hidden"}
  }, /* @__PURE__ */ React.createElement("span", null, device.name), /* @__PURE__ */ React.createElement("span", {
    style: {paddingTop: 6}
  }, currentlyPlaying.currentMedia.title), currentlyPlaying.currentMedia.artist && /* @__PURE__ */ React.createElement(Typography2, {
    variant: "body2"
  }, currentlyPlaying.currentMedia.artist)), /* @__PURE__ */ React.createElement("div", {
    style: {
      ...row({horizontal: "center", vertical: "center"}),
      paddingRight: 8
    }
  }, /* @__PURE__ */ React.createElement(IconButton2, {
    onClick: (e) => {
      e.stopPropagation();
      call(defaultInterfaces.CurrentlyPlaying.id, currentlyPlaying.playerState === "playing" ? defaultInterfaces.CurrentlyPlaying.methods.pause.id : defaultInterfaces.CurrentlyPlaying.methods.play.id);
    }
  }, /* @__PURE__ */ React.createElement(Icon2, null, currentlyPlaying.playerState === "playing" ? /* @__PURE__ */ React.createElement(PauseIcon, null) : /* @__PURE__ */ React.createElement(PlayArrowIcon, null)))), /* @__PURE__ */ React.createElement(Suspense, {
    fallback: null
  }, /* @__PURE__ */ React.createElement(LazyMediaDialog, {
    open: dialogOpen,
    onClose: () => setDialogOpen(false),
    device,
    currentlyPlaying,
    call
  }))) : null;
};
