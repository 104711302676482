import './index-7c2bbddc.js';
import { u as useTheme$1, a as defaultTheme } from './defaultTheme-7c534de9.js';

function useTheme() {
  var theme = useTheme$1() || defaultTheme;

  return theme;
}

export { useTheme as u };
