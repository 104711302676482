import {gql, useMutation} from "../../web_modules/@apollo/client.js";
import {useCallback, useEffect, useRef, useState} from "../../web_modules/react.js";
import {throttle} from "../../web_modules/throttle-debounce.js";
import {useSnackbar} from "../snackbar/index.js";
export function useUpdateDeviceStatus(currentValue) {
  const [tmpValue, setTmpValue] = useState(void 0);
  const isLiveAction = useRef(false);
  const lastLiveUpdate = useRef(void 0);
  const snackbar2 = useSnackbar();
  useEffect(() => {
    if (!isLiveAction.current) {
      setTmpValue(void 0);
    }
  }, [currentValue]);
  const [updateDeviceStatus] = useMutation(gql`
    mutation(
      $deviceId: String!
      $interfaceId: String!
      $statusId: String!
      $value: JSON!
    ) {
      setDeviceStatus(
        deviceId: $deviceId
        interfaceId: $interfaceId
        statusId: $statusId
        value: $value
      ) {
        id
        value
      }
    }
  `);
  const commitUpdate = useCallback((modification) => {
    if (isLiveAction.current) {
      isLiveAction.current = false;
      if (lastLiveUpdate.current !== void 0 && lastLiveUpdate.current === modification.value) {
        lastLiveUpdate.current = void 0;
        return;
      }
      lastLiveUpdate.current = void 0;
    }
    setTmpValue(modification.value);
    updateDeviceStatus({variables: modification}).catch((err) => {
      setTmpValue(void 0);
      console.error(`Failed updating status for device ${modification.deviceId}`, err);
      snackbar2.showSnackbar({
        label: `Failed updating status for device ${modification.deviceId}`,
        type: "warning"
      });
    });
  }, []);
  const [liveCommit] = useState(() => throttle(1e3, false, (modification) => {
    updateDeviceStatus({variables: modification}).catch((err) => {
      console.error(`Failed live updating status for device ${modification.deviceId}`, err);
    });
  }));
  const liveUpdate = useCallback((modification) => {
    isLiveAction.current = true;
    setTmpValue(modification.value);
    liveCommit(modification);
  }, []);
  return {
    displayValue: tmpValue === void 0 ? currentValue : tmpValue,
    commitUpdate,
    liveUpdate
  };
}
export function useCallDevice() {
  const snackbar2 = useSnackbar();
  const [callDevice] = useMutation(gql`
      mutation($deviceId: String!, $interfaceId: String!, $method: String!) {
        callDevice(
          deviceId: $deviceId
          interfaceId: $interfaceId
          method: $method
        ) {
          id
        }
      }
    `);
  return (call) => callDevice({variables: call}).catch((error) => {
    console.error("Failed to call device", error);
    snackbar2.showSnackbar({
      label: "Failed to call device",
      type: "warning"
    });
  });
}
