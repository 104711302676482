var animationCurve = {
    /**
     * The standard curve (also referred to as “ease in out”) is the most common
     * easing curve. Elements quickly accelerate and slowly decelerate between
     * on-screen locations.
     * It applies to growing and shrinking material, among other property changes.
     */
    standard: 'cubic-bezier(0.4, 0.0, 0.2, 1)',
    /**
     * Using the deceleration curve (also referred to as “ease out”) elements
     * enter the screen at full velocity and slowly decelerate to a resting point.
     *
     * During deceleration, elements may scale up either in size (to 100%) or
     * opacity (to 100%). In some cases, when elements enter the screen at 0% opacity,
     * they may slightly shrink from a larger size upon entry.
     */
    deceleration: 'cubic-bezier(0.0, 0.0, 0.2, 1)',
    /**
     * Using the acceleration curve (also referred to as “ease in”) elements
     * leave the screen at full velocity. They do not decelerate when off-screen.
     *
     * They accelerate at the beginning of the animation and may scale down
     * in either size (to 0%) or opacity (to 0%). In some cases, when elements
     * leave the screen at 0% opacity, they may also slightly scale up or down in size.
     */
    acceleration: 'cubic-bezier(0.4, 0.0, 1, 1)',
    /**
     * Using the sharp curve (also referred to as “ease in out”) elements quickly
     * accelerate and decelerate. It is used by exiting elements that may return
     * to the screen at any time.
     *
     * Elements may quickly accelerate from a starting point on-screen, then
     * quickly decelerate in a symmetrical curve to a resting point immediately
     * off-screen. The deceleration is faster than the standard curve since it
     * doesn't follow an exact path to the off-screen point.
     * Elements may return from that point at any time.
     */
    sharp: 'cubic-bezier(0.4, 0.0, 0.6, 1)'
};
/**
 * Animation durations in ms
 */
var animationDuration = {
    /**
     * The default duration value for elevation transitions.
     */
    elevation: 280,
    large: 375,
    enter: 225,
    leave: 195,
    mobile: {
        large: 375,
        enter: 225,
        leave: 195
    },
    tablet: {
        /** 375 * 1.3 */
        large: 487.5,
        /** 225 * 1.3 */
        enter: 292.5,
        /** 195 * 1.3 */
        leave: 253.5
    },
    desktop: {
        /** 375 * 0.7 */
        large: 262.5,
        /** 225 * 0.7 */
        enter: 157.5,
        /** 195 * 0.7 */
        leave: 136.5
    }
};

var red = {
    50: '#ffebee',
    100: '#ffcdd2',
    200: '#ef9a9a',
    300: '#e57373',
    400: '#ef5350',
    500: '#f44336',
    600: '#e53935',
    700: '#d32f2f',
    800: '#c62828',
    900: '#b71c1c',
    A100: '#ff8a80',
    A200: '#ff5252',
    A400: '#ff1744',
    A700: '#d50000'
};
var grey = {
    50: '#fafafa',
    100: '#f5f5f5',
    200: '#eee',
    300: '#e0e0e0',
    400: '#bdbdbd',
    500: '#9e9e9e',
    600: '#757575',
    700: '#616161',
    800: '#424242',
    900: '#212121'
};

var umbraColor = 'rgba(0, 0, 0, .2)';
var penumbraColor = 'rgba(0, 0, 0, .14)';
var ambientColor = 'rgba(0, 0, 0, .12)';
var umbraMap = {
    0: '0 0 0 0',
    1: '0 2px 1px -1px',
    2: '0 3px 1px -2px',
    3: '0 3px 3px -2px',
    4: '0 2px 4px -1px',
    5: '0 3px 5px -1px',
    6: '0 3px 5px -1px',
    7: '0 4px 5px -2px',
    8: '0 5px 5px -3px',
    9: '0 5px 6px -3px',
    10: '0 6px 6px -3px',
    11: '0 6px 7px -4px',
    12: '0 7px 8px -4px',
    13: '0 7px 8px -4px',
    14: '0 7px 9px -4px',
    15: '0 8px 9px -5px',
    16: '0 8px 10px -5px',
    17: '0 8px 11px -5px',
    18: '0 9px 11px -5px',
    19: '0 9px 12px -6px',
    20: '0 10px 13px -6px',
    21: '0 10px 13px -6px',
    22: '0 10px 14px -6px',
    23: '0 11px 14px -7px',
    24: '0 11px 15px -7px'
};
var penumbraMap = {
    0: '0 0 0 0',
    1: '0 1px 1px 0',
    2: '0 2px 2px 0',
    3: '0 3px 4px 0',
    4: '0 4px 5px 0',
    5: '0 5px 8px 0',
    6: '0 6px 10px 0',
    7: '0 7px 10px 1px',
    8: '0 8px 10px 1px',
    9: '0 9px 12px 1px',
    10: '0 10px 14px 1px',
    11: '0 11px 15px 1px',
    12: '0 12px 17px 2px',
    13: '0 13px 19px 2px',
    14: '0 14px 21px 2px',
    15: '0 15px 22px 2px',
    16: '0 16px 24px 2px',
    17: '0 17px 26px 2px',
    18: '0 18px 28px 2px',
    19: '0 19px 29px 2px',
    20: '0 20px 31px 3px',
    21: '0 21px 33px 3px',
    22: '0 22px 35px 3px',
    23: '0 23px 36px 3px',
    24: '0 24px 38px 3px'
};
var ambientMap = {
    0: '0 0 0 0',
    1: '0 1px 3px 0',
    2: '0 1px 5px 0',
    3: '0 1px 8px 0',
    4: '0 1px 10px 0',
    5: '0 1px 14px 0',
    6: '0 1px 18px 0',
    7: '0 2px 16px 1px',
    8: '0 3px 14px 2px',
    9: '0 3px 16px 2px',
    10: '0 4px 18px 3px',
    11: '0 4px 20px 3px',
    12: '0 5px 22px 4px',
    13: '0 5px 24px 4px',
    14: '0 5px 26px 4px',
    15: '0 6px 28px 5px',
    16: '0 6px 30px 5px',
    17: '0 6px 32px 5px',
    18: '0 7px 34px 6px',
    19: '0 7px 36px 6px',
    20: '0 8px 38px 7px',
    21: '0 8px 40px 7px',
    22: '0 8px 42px 7px',
    23: '0 9px 44px 8px',
    24: '0 9px 46px 8px'
};
var shadow = {
    1: {
        boxShadow: umbraMap[1] + ' ' + umbraColor + ', ' + penumbraMap[1] + ' ' + penumbraColor + ', ' + ambientMap[1] + ' ' + ambientColor
    },
    2: {
        boxShadow: umbraMap[2] + ' ' + umbraColor + ', ' + penumbraMap[2] + ' ' + penumbraColor + ', ' + ambientMap[2] + ' ' + ambientColor
    },
    3: {
        boxShadow: umbraMap[3] + ' ' + umbraColor + ', ' + penumbraMap[3] + ' ' + penumbraColor + ', ' + ambientMap[3] + ' ' + ambientColor
    },
    4: {
        boxShadow: umbraMap[4] + ' ' + umbraColor + ', ' + penumbraMap[4] + ' ' + penumbraColor + ', ' + ambientMap[4] + ' ' + ambientColor
    },
    5: {
        boxShadow: umbraMap[5] + ' ' + umbraColor + ', ' + penumbraMap[5] + ' ' + penumbraColor + ', ' + ambientMap[5] + ' ' + ambientColor
    },
    6: {
        boxShadow: umbraMap[6] + ' ' + umbraColor + ', ' + penumbraMap[6] + ' ' + penumbraColor + ', ' + ambientMap[6] + ' ' + ambientColor
    },
    7: {
        boxShadow: umbraMap[7] + ' ' + umbraColor + ', ' + penumbraMap[7] + ' ' + penumbraColor + ', ' + ambientMap[7] + ' ' + ambientColor
    },
    8: {
        boxShadow: umbraMap[8] + ' ' + umbraColor + ', ' + penumbraMap[8] + ' ' + penumbraColor + ', ' + ambientMap[8] + ' ' + ambientColor
    },
    9: {
        boxShadow: umbraMap[9] + ' ' + umbraColor + ', ' + penumbraMap[9] + ' ' + penumbraColor + ', ' + ambientMap[9] + ' ' + ambientColor
    },
    10: {
        boxShadow: umbraMap[10] + ' ' + umbraColor + ', ' + penumbraMap[10] + ' ' + penumbraColor + ', ' + ambientMap[10] + ' ' + ambientColor
    },
    11: {
        boxShadow: umbraMap[11] + ' ' + umbraColor + ', ' + penumbraMap[11] + ' ' + penumbraColor + ', ' + ambientMap[11] + ' ' + ambientColor
    },
    12: {
        boxShadow: umbraMap[12] + ' ' + umbraColor + ', ' + penumbraMap[12] + ' ' + penumbraColor + ', ' + ambientMap[12] + ' ' + ambientColor
    },
    13: {
        boxShadow: umbraMap[13] + ' ' + umbraColor + ', ' + penumbraMap[13] + ' ' + penumbraColor + ', ' + ambientMap[13] + ' ' + ambientColor
    },
    14: {
        boxShadow: umbraMap[14] + ' ' + umbraColor + ', ' + penumbraMap[14] + ' ' + penumbraColor + ', ' + ambientMap[14] + ' ' + ambientColor
    },
    15: {
        boxShadow: umbraMap[15] + ' ' + umbraColor + ', ' + penumbraMap[15] + ' ' + penumbraColor + ', ' + ambientMap[15] + ' ' + ambientColor
    },
    16: {
        boxShadow: umbraMap[16] + ' ' + umbraColor + ', ' + penumbraMap[16] + ' ' + penumbraColor + ', ' + ambientMap[16] + ' ' + ambientColor
    },
    17: {
        boxShadow: umbraMap[17] + ' ' + umbraColor + ', ' + penumbraMap[17] + ' ' + penumbraColor + ', ' + ambientMap[17] + ' ' + ambientColor
    },
    18: {
        boxShadow: umbraMap[18] + ' ' + umbraColor + ', ' + penumbraMap[18] + ' ' + penumbraColor + ', ' + ambientMap[18] + ' ' + ambientColor
    },
    19: {
        boxShadow: umbraMap[19] + ' ' + umbraColor + ', ' + penumbraMap[19] + ' ' + penumbraColor + ', ' + ambientMap[19] + ' ' + ambientColor
    },
    20: {
        boxShadow: umbraMap[20] + ' ' + umbraColor + ', ' + penumbraMap[20] + ' ' + penumbraColor + ', ' + ambientMap[20] + ' ' + ambientColor
    },
    21: {
        boxShadow: umbraMap[21] + ' ' + umbraColor + ', ' + penumbraMap[21] + ' ' + penumbraColor + ', ' + ambientMap[21] + ' ' + ambientColor
    },
    22: {
        boxShadow: umbraMap[22] + ' ' + umbraColor + ', ' + penumbraMap[22] + ' ' + penumbraColor + ', ' + ambientMap[22] + ' ' + ambientColor
    },
    23: {
        boxShadow: umbraMap[23] + ' ' + umbraColor + ', ' + penumbraMap[23] + ' ' + penumbraColor + ', ' + ambientMap[23] + ' ' + ambientColor
    },
    24: {
        boxShadow: umbraMap[24] + ' ' + umbraColor + ', ' + penumbraMap[24] + ' ' + penumbraColor + ', ' + ambientMap[24] + ' ' + ambientColor
    }
};

var fontWeight = {
    thin: 100,
    light: 300,
    regular: 400,
    medium: 500,
    bold: 700,
    black: 900
};
var title = {
    fontSize: '1.25rem' /* 20sp */
    , lineHeight: '2rem' /* 32sp */
    , fontWeight: fontWeight.medium,
    letterSpacing: '.02em,'
};
var subheading1 = {
    fontSize: '.938rem' /* 15sp */
    , lineHeight: '1.5rem' /* 24sp */
    , fontWeight: fontWeight.regular,
    letterSpacing: '.04em,'
};

export { animationCurve, animationDuration, grey, red, shadow, subheading1, title };
