import React from "../../web_modules/react.js";
export const createStore = () => {
  const subscribers = new Set();
  let settings = {
    theme: "white"
  };
  try {
    settings = {
      ...settings,
      ...JSON.parse(localStorage.getItem("raxaSettings") || "{}")
    };
  } catch (_) {
  }
  return {
    getState: () => settings,
    update: (updated) => {
      settings = {...settings, ...updated};
      localStorage.setItem("raxaSettings", JSON.stringify(settings));
      for (const subscriber of subscribers) {
        subscriber(settings);
      }
    },
    subscribe: (subscriber) => subscribers.add(subscriber),
    unsubscribe: (subscriber) => subscribers.delete(subscriber)
  };
};
export const localSettingsStore = createStore();
export class LocalSettingsProvider extends React.Component {
  constructor() {
    super(...arguments);
    this.state = {settings: this.props.store.getState()};
    this.subscriber = (settings) => {
      this.setState({settings});
    };
  }
  componentDidMount() {
    this.props.store.subscribe(this.subscriber);
  }
  componentWillUnmount() {
    this.props.store.unsubscribe(this.subscriber);
  }
  render() {
    return this.state.settings === void 0 ? null : this.props.children(this.state.settings);
  }
}
