import { c as createCommonjsModule } from './_commonjsHelpers-bc388fbf.js';

var interopRequireDefault = createCommonjsModule(function (module) {
function _interopRequireDefault(obj) {
  return obj && obj.__esModule ? obj : {
    "default": obj
  };
}

module.exports = _interopRequireDefault;
});

export { interopRequireDefault as i };
