import React, {createContext} from "../../web_modules/react.js";
export function localStorageMiddleware(key) {
  return (state, {isInitialLoad}) => {
    if (isInitialLoad) {
      try {
        state = JSON.parse(window.localStorage.getItem(key)) || state;
      } catch (_) {
      }
    }
    window.localStorage.setItem(key, JSON.stringify(state));
    return state;
  };
}
export function createContextState(mutators, options = {}) {
  const context = createContext(void 0);
  const applyMiddlewares = (middlewares, state, meta) => middlewares === void 0 ? state : middlewares.reduce((state2, middleware) => middleware(state2, meta), state);
  class StateProvider extends React.Component {
    constructor(props) {
      super(props);
      this.setContextState = (stateUpdate) => {
        const state = applyMiddlewares(this.props.middlewares, {
          ...this.state.state,
          ...stateUpdate
        }, {isInitialLoad: false});
        this.setState({...this.mutators, ...this.mutatorContext, state});
      };
      const state = applyMiddlewares(props.middlewares, props.initialState || options.initialState, {isInitialLoad: true});
      this.mutatorContext = {
        getState: () => this.state.state,
        setState: (stateUpdate) => this.setContextState(stateUpdate)
      };
      this.mutators = mutators(this.mutatorContext);
      this.state = {...this.mutators, ...this.mutatorContext, state};
      if (options.globalName) {
        ;
        window[options.globalName] = this;
      }
    }
    render() {
      return /* @__PURE__ */ React.createElement(context.Provider, {
        value: this.state
      }, this.props.children);
    }
  }
  const StateConsumer = context.Consumer;
  return {StateProvider, StateConsumer, context};
}
