import { r as react } from './index-7c2bbddc.js';

/**
 * Key used for specifying an error message for required fields
 *
 * Example:
 * ```
 * {
 *   path: ['email'],
 *   label: 'Email',
 *   type: 'email',
 *   required: true,
 *   validations: {
 *     [required]: {text: 'A valid email is required'},
 *   },
 * }
 * ```
 */
var required = 'required';

var __assign = undefined && undefined.__assign || Object.assign || function (t) {
    for (var s, i = 1, n = arguments.length; i < n; i++) {
        s = arguments[i];
        for (var p in s) {
            if (Object.prototype.hasOwnProperty.call(s, p)) t[p] = s[p];
        }
    }
    return t;
};
function isValid(fields, updatedObject) {
    var validatedFields = [];
    var valid = true;
    for (var _i = 0, fields_1 = fields; _i < fields_1.length; _i++) {
        var field = fields_1[_i];
        if (!field || react.isValidElement(field)) {
            validatedFields.push(field);
            continue;
        }
        var typedField = field;
        var value = getValue(typedField.path, updatedObject);
        var required$1 = typeof typedField.required === 'function' ? typedField.required(updatedObject) : typedField.required;
        if (required$1 && (value === '' || value === null || value === undefined)) {
            valid = false;
            validatedFields.push(Object.assign({}, typedField, { validationError: required }));
            continue;
        }
        var fieldValid = true;
        if (typedField.validations) {
            for (var _a = 0, _b = Object.entries(typedField.validations); _a < _b.length; _a++) {
                var _c = _b[_a],
                    type = _c[0],
                    validation = _c[1];
                if (value && validation.test && !validation.test(value, updatedObject)) {
                    fieldValid = false;
                    validatedFields.push(Object.assign({}, typedField, { validationError: type }));
                    break;
                }
            }
            if (!fieldValid) {
                valid = false;
                continue;
            }
        }
        if (typedField.error) {
            valid = false;
        }
        if (typedField.validationError) {
            valid = false;
        }
        if (fieldValid) {
            validatedFields.push(typedField);
        }
    }
    return { validatedFields: validatedFields, valid: valid };
}
function getValue(path, object) {
    return path.reduce(function (object, key) {
        return object && object[key];
    }, object);
}
function clone(object) {
    return Array.isArray(object) ? object.slice() : __assign({}, object);
}
function updateIn(path, newValue, object) {
    if (Array.isArray(path) && path.length > 1) {
        newValue = updateIn(path.slice(1), newValue, (object || {})[path[0]]);
    }
    var key = Array.isArray(path) ? path[0] : path;
    var cloned = clone(object);
    cloned[key] = newValue;
    return cloned;
}

export { clone as c, getValue as g, isValid as i, updateIn as u };
