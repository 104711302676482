import {gql, useQuery} from "../../web_modules/@apollo/client.js";
import React from "../../web_modules/react.js";
const getDeviceName = gql`
  query($deviceId: String!) {
    device(id: $deviceId) {
      id
      name
    }
  }
`;
export const DeviceName = ({id: deviceId, ...props}) => {
  const query = useQuery(getDeviceName, {
    skip: !deviceId,
    variables: {deviceId}
  });
  return /* @__PURE__ */ React.createElement("span", {
    ...props
  }, query.data && (query.data.device?.name ?? "[missing device]"));
};
