var flex = function flex(_flex) {
    return {
        flexGrow: _flex === true ? 1 : _flex === false ? undefined : _flex === undefined ? undefined : typeof _flex === 'number' ? _flex : _flex.grow,
        flexShrink: _flex === true ? undefined : _flex === false ? undefined : _flex === undefined ? undefined : typeof _flex === 'number' ? _flex : _flex.grow
    };
};
var column = function column() {
    var _ref = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        horizontal = _ref.horizontal,
        vertical = _ref.vertical,
        alignSelf = _ref.alignSelf,
        reverse = _ref.reverse,
        flex_ = _ref.flex;

    return Object.assign({ display: 'flex', flexDirection: reverse ? 'column-reverse' : 'column', alignItems: horizontal, justifyContent: vertical, alignSelf: alignSelf }, flex_ === undefined ? undefined : flex(flex_));
};
var row = function row() {
    var _ref2 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        horizontal = _ref2.horizontal,
        vertical = _ref2.vertical,
        alignSelf = _ref2.alignSelf,
        reverse = _ref2.reverse,
        flex_ = _ref2.flex;

    return Object.assign({ display: 'flex', flexDirection: reverse ? 'row-reverse' : 'row', alignItems: vertical, justifyContent: horizontal, alignSelf: alignSelf }, flex_ === undefined ? undefined : flex(flex_));
};
var fill = function fill() {
    var _ref3 = arguments.length > 0 && arguments[0] !== undefined ? arguments[0] : {},
        _ref3$padding = _ref3.padding,
        padding = _ref3$padding === undefined ? 0 : _ref3$padding;

    return {
        position: 'absolute',
        top: padding,
        left: padding,
        right: padding,
        bottom: padding
    };
};
var center = row({ horizontal: 'center', vertical: 'center' });

export { center, column, fill, flex, row };
