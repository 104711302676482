import {deepOrange, purple, teal} from "../web_modules/@material-ui/core/colors.js";
import {createMuiTheme} from "../web_modules/@material-ui/core/styles.js";
import {grey} from "../web_modules/material-definitions.js";
import {createContext, useContext} from "../web_modules/react.js";
const themeContext = createContext(void 0);
export const ThemeProvider = themeContext.Provider;
export const ThemeConsumer = themeContext.Consumer;
export const useTheme = () => useContext(themeContext);
export function createTheme(theme) {
  if (theme === "white") {
    return {
      theme: {
        dark: false,
        primary: purple[500],
        accent: teal[500],
        background: {
          light: grey[100],
          main: "white",
          dark: grey[500],
          text: "rgba(0, 0, 0, 0.87)",
          textLight: "rgba(0, 0, 0, 0.5)",
          textDisabled: "rgba(0, 0, 0, 0.3)"
        }
      },
      muiTheme: createMuiTheme({
        palette: {
          primary: {
            light: purple[100],
            main: purple[500],
            dark: purple[700],
            contrastText: "#fff"
          },
          secondary: {
            light: teal[100],
            main: teal[500],
            dark: teal[700],
            contrastText: "#fff"
          }
        }
      })
    };
  } else {
    return {
      theme: {
        dark: true,
        primary: purple[500],
        accent: deepOrange[500],
        background: {
          light: "#32313c",
          main: "#1c1b22",
          dark: "#3f3e46",
          text: "#fbfbfe",
          textLight: "rgba(255, 255, 255, 0.7)",
          textDisabled: "#82818a"
        }
      },
      muiTheme: createMuiTheme({
        palette: {
          type: "dark",
          background: {
            default: "#1c1b22",
            paper: "#3f3e46"
          },
          primary: {
            light: purple[100],
            main: purple[500],
            dark: purple[700],
            contrastText: "#fbfbfe"
          },
          secondary: {
            light: deepOrange[100],
            main: deepOrange[500],
            dark: deepOrange[700],
            contrastText: "#fbfbfe"
          }
        }
      })
    };
  }
}
