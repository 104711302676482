import SettingsIcon from "../../../web_modules/@material-ui/icons/Settings.js";
import {Link} from "../../../web_modules/@reach/router.js";
import {defaultInterfaces} from "../../../web_modules/raxa-common/lib/default-interfaces.js";
import {DeviceType} from "../../../web_modules/raxa-common/lib/entities.js";
import React from "../../../web_modules/react.js";
import styled2 from "../../../web_modules/@emotion/styled.js";
import {createContextState, localStorageMiddleware} from "../../lib/state.js";
import {ListWidget} from "../dashboard/widgets/list.js";
import {ContextActions as ContextActions2} from "../ui/ContextActions.js";
export const {
  StateProvider: DashboardStateProvider,
  StateConsumer: DashboardStateConsumer
} = createContextState((context) => ({
  configureSection: (section, configuration) => context.setState({
    [section]: {
      hidden: [],
      sortOrder: [],
      ...context.getState()[section],
      ...configuration
    }
  })
}), {
  globalName: "dashboardState"
});
const Ui2Container = styled2("div")(({theme}) => ({
  flexGrow: 1,
  boxSizing: "border-box",
  paddingTop: 8,
  height: "100%",
  color: theme.background.text,
  backgroundColor: theme.dark ? theme.background.main : theme.background.light
}));
const getOldState = () => {
  try {
    return JSON.parse(window.localStorage.getItem("reduxPersist:mainScreen")) || {};
  } catch (_) {
    return {};
  }
};
export const Ui2 = () => /* @__PURE__ */ React.createElement(DashboardStateProvider, {
  initialState: getOldState(),
  middlewares: [localStorageMiddleware("dashboardState")]
}, /* @__PURE__ */ React.createElement(DashboardStateConsumer, null, ({state: configurations, configureSection}) => /* @__PURE__ */ React.createElement(Ui2Container, null, /* @__PURE__ */ React.createElement(ContextActions2, {
  contextActions: [
    {
      component: Link,
      props: {to: "/settings"},
      icon: /* @__PURE__ */ React.createElement(SettingsIcon, null),
      ariaLabel: "Settings"
    }
  ]
}), /* @__PURE__ */ React.createElement(ListWidget, {
  header: "Scenes",
  variant: "row",
  interfaceIds: ["Scenery"],
  configuration: configurations.Scenes,
  setConfiguration: (configuration) => configureSection("Scenes", configuration)
}), /* @__PURE__ */ React.createElement(ListWidget, {
  header: "Lighting",
  variant: "column",
  types: [DeviceType.Light, DeviceType.Outlet],
  interfaceIds: [
    defaultInterfaces.Power.id,
    defaultInterfaces.Dimmer.id,
    defaultInterfaces.HueSat.id
  ],
  configuration: configurations.Lighting,
  setConfiguration: (configuration) => configureSection("Lighting", configuration)
}), /* @__PURE__ */ React.createElement(ListWidget, {
  header: "Media",
  variant: "column",
  big: true,
  interfaceIds: [defaultInterfaces.CurrentlyPlaying.id],
  configuration: configurations.Media,
  setConfiguration: (configuration) => configureSection("Media", configuration)
}), /* @__PURE__ */ React.createElement(ListWidget, {
  header: "Sensors",
  variant: "column",
  interfaceIds: [
    defaultInterfaces.Temperature.id,
    defaultInterfaces.RelativeHumidity.id,
    defaultInterfaces.AirQualityIndex.id
  ],
  configuration: configurations.Sensors,
  setConfiguration: (configuration) => configureSection("Sensors", configuration)
}))));
