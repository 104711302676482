import {gql, useQuery} from "../../../../web_modules/@apollo/client.js";
import styled2 from "../../../../web_modules/@emotion/styled.js";
import ButtonBase2 from "../../../../web_modules/@material-ui/core/ButtonBase.js";
import React, {Fragment, Suspense, lazy, useMemo, useState} from "../../../../web_modules/react.js";
import {LazyDialog} from "../../ui/lazy-loading.js";
const LazyGraphDialogContent = lazy(() => import("./ui/graph-dialog.js").then((module) => ({
  default: module.GraphDialogContent
})));
const GraphDialog = ({
  open,
  onClose,
  device
}) => /* @__PURE__ */ React.createElement(Suspense, {
  fallback: null
}, /* @__PURE__ */ React.createElement(LazyDialog, {
  fullScreen: true,
  open,
  onClose,
  onMouseDown: (e) => e.stopPropagation(),
  onTouchStart: (e) => e.stopPropagation(),
  onClick: (e) => e.stopPropagation(),
  disableEnforceFocus: true
}, open && /* @__PURE__ */ React.createElement(LazyGraphDialogContent, {
  onClose,
  device
})));
const ButtonBaseDiv = (props) => /* @__PURE__ */ React.createElement(ButtonBase2, {
  component: "div",
  ...props
});
const Container = styled2(ButtonBaseDiv)({
  "&&": {
    padding: 8
  },
  boxSizing: "border-box",
  width: "100%",
  height: "100%",
  minHeight: 48,
  "& > div": {
    width: "100%"
  }
});
const getDevice = gql`
  query($deviceId: String!, $interfaceIds: [String!], $statusIds: [String!]) {
    device(id: $deviceId) {
      id
      name
      status(interfaceIds: $interfaceIds, statusIds: $statusIds) {
        id
        interfaceId
        statusId
        value
      }
    }
    interfaces(ids: $interfaceIds) {
      id
      status
    }
  }
`;
export const DisplayWidget = ({deviceId, statuses}) => {
  const [dialogOpen, setDialogOpen] = useState(false);
  const {interfaceIds, statusIds} = useMemo(() => ({
    interfaceIds: Object.keys(statuses),
    statusIds: Object.values(statuses).flat()
  }), [statuses]);
  const query = useQuery(getDevice, {
    skip: !deviceId,
    variables: {
      deviceId,
      interfaceIds,
      statusIds
    }
  });
  const device = query.data?.device;
  const statusesWithDefinitions = device?.status?.flatMap((status) => {
    const statusDefinition = query.data?.interfaces.find((iface) => iface.id === status.interfaceId)?.status?.[status.statusId];
    if (statusDefinition?.type !== "number")
      return [];
    if (status?.value == null)
      return [];
    return {status, statusDefinition};
  });
  return /* @__PURE__ */ React.createElement(Container, {
    onClick: () => setDialogOpen(true)
  }, /* @__PURE__ */ React.createElement("div", {
    style: {
      display: "flex",
      alignItems: "center",
      paddingRight: 8
    }
  }, /* @__PURE__ */ React.createElement("span", {
    style: {flex: 1}
  }, device && device.name), statusesWithDefinitions?.length === 1 ? statusesWithDefinitions?.map(({status, statusDefinition}) => {
    return /* @__PURE__ */ React.createElement("span", {
      key: status.id
    }, status.value, statusDefinition.unit && ` ${statusDefinition.unit}`);
  }) : /* @__PURE__ */ React.createElement("div", {
    style: {
      display: "grid",
      gridTemplateColumns: "auto minmax(58px, auto)",
      rowGap: 4,
      columnGap: "1ex"
    }
  }, statusesWithDefinitions?.map(({status, statusDefinition}) => {
    return /* @__PURE__ */ React.createElement(Fragment, {
      key: status.id
    }, /* @__PURE__ */ React.createElement("span", {
      style: {flex: 1}
    }, statusDefinition.name ?? statusDefinition.id), /* @__PURE__ */ React.createElement("span", {
      style: {textAlign: "right"}
    }, status.value, statusDefinition.unit && ` ${statusDefinition.unit}`));
  }))), device && /* @__PURE__ */ React.createElement(GraphDialog, {
    open: dialogOpen,
    onClose: () => setDialogOpen(false),
    device
  }));
};
