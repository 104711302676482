import {ApolloProvider} from "../web_modules/@apollo/client.js";
import {MuiThemeProvider} from "../web_modules/@material-ui/core/styles.js";
import {Router} from "../web_modules/@reach/router.js";
import {ThemeProvider as EmotionThemeProvider} from "../web_modules/@emotion/react.js";
import React, {Suspense, lazy} from "../web_modules/react.js";
import styled2 from "../web_modules/@emotion/styled.js";
import {row} from "../web_modules/style-definitions.js";
import {Scaffold} from "./components/ui/scaffold.js";
import {Ui2} from "./components/ui2/main.js";
import {LocalSettingsProvider, localSettingsStore} from "./lib/local-settings.js";
import {Route} from "./lib/route.js";
import {client as client2} from "./lib/store.js";
import {Snackbar, SnackbarProvider} from "./snackbar/index.js";
import {ThemeProvider, createTheme} from "./theme.js";
export const LazySettings = lazy(() => import("./components/settings/settings.js").then((module) => ({
  default: module.Settings
})));
const Container = styled2("div")(({theme: theme2}) => ({
  display: "flex",
  flex: 1,
  color: theme2.background.text,
  backgroundColor: theme2.background.main,
  "&>div>div>div": {
    display: "flex"
  }
}));
export const App = () => /* @__PURE__ */ React.createElement(ApolloProvider, {
  client: client2
}, /* @__PURE__ */ React.createElement(SnackbarProvider, null, /* @__PURE__ */ React.createElement(LocalSettingsProvider, {
  store: localSettingsStore
}, ({theme: themeName}) => {
  const {muiTheme, theme: theme2} = createTheme(themeName);
  return /* @__PURE__ */ React.createElement(MuiThemeProvider, {
    theme: muiTheme
  }, /* @__PURE__ */ React.createElement(EmotionThemeProvider, {
    theme: theme2
  }, /* @__PURE__ */ React.createElement(ThemeProvider, {
    value: theme2
  }, /* @__PURE__ */ React.createElement(Container, null, /* @__PURE__ */ React.createElement(Scaffold, {
    appName: "Raxa"
  }, /* @__PURE__ */ React.createElement(Router, {
    style: row({flex: 1})
  }, /* @__PURE__ */ React.createElement(Route, {
    path: "/",
    component: Ui2
  }), /* @__PURE__ */ React.createElement(Route, {
    path: "/settings/*",
    render: () => /* @__PURE__ */ React.createElement(Suspense, {
      fallback: null
    }, /* @__PURE__ */ React.createElement(LazySettings, null))
  })), /* @__PURE__ */ React.createElement(Suspense, {
    fallback: null
  }, /* @__PURE__ */ React.createElement(Snackbar, null)))))));
})));
