import ButtonBase2 from "../../../../web_modules/@material-ui/core/ButtonBase.js";
import {subheading1} from "../../../../web_modules/material-definitions.js";
import React from "../../../../web_modules/react.js";
import styled2 from "../../../../web_modules/@emotion/styled.js";
import {useCallDevice} from "../../../lib/mutations.js";
import {DeviceName} from "../../device-name.js";
const Container = styled2(ButtonBase2)({
  "&&": {
    display: "flex",
    padding: 16
  },
  boxSizing: "border-box",
  height: "100%",
  width: "100%",
  ...subheading1
});
export const ButtonWidget = ({
  deviceId,
  interfaceId,
  method
}) => {
  const callDevice = useCallDevice();
  return /* @__PURE__ */ React.createElement(Container, {
    onClick: () => callDevice({
      deviceId,
      interfaceId,
      method,
      arguments: void 0
    })
  }, /* @__PURE__ */ React.createElement(DeviceName, {
    id: deviceId,
    style: {flex: 1}
  }));
};
