
      function getAbsoluteUrl(relativeUrl) {
        const publicPath = __webpack_public_path__;
        let url = '';
        if (!publicPath || publicPath.indexOf('://') < 0) {
          url += window.location.protocol + '//' + window.location.host;
        }
        if (publicPath) {
          url += publicPath;
        } else {
          url += '/';
        }
        return url + relativeUrl;
      }
import __SNOWPACK_ENV__ from '../../__snowpack__/env.js';
({ url: getAbsoluteUrl('_dist_/lib/store.js'), env: __SNOWPACK_ENV__ }).env = __SNOWPACK_ENV__;

import {
  ApolloClient,
  ApolloLink,
  HttpLink,
  InMemoryCache,
  Observable,
  gql,
  split
} from "../../web_modules/@apollo/client.js";
import {onError} from "../../web_modules/@apollo/client/link/error.js";
import {getMainDefinition} from "../../web_modules/@apollo/client/utilities.js";
const ssl = localStorage.getItem("debug:host") ? localStorage.getItem("debug:host").startsWith("https") : window.location.protocol === "https:";
const port = ({ url: getAbsoluteUrl('_dist_/lib/store.js'), env: __SNOWPACK_ENV__ }).env.MODE === "production" ? window.location.port && `:${window.location.port}` : `:${ssl ? 9001 : 9800}`;
const host = localStorage.getItem("debug:host") ? localStorage.getItem("debug:host").replace(/^https?:\/\//, "") : `${window.location.hostname}${port}`;
export const baseHttpUrl = `${ssl ? "https" : "http"}://${host}`;
const httpLink = new HttpLink({
  uri: `${baseHttpUrl}/graphql`
});
let wsLink;
let wsLinkPromise = new Promise((resolve, reject) => {
  setTimeout(() => {
    import("./ws-link.js").then(({createWsLink}) => {
      wsLink = createWsLink({ssl, host});
      resolve();
    }, reject);
  }, 1e3);
});
class LazyWsLink extends ApolloLink {
  request(operation) {
    return new Observable(function subscriber(sink) {
      if (!wsLink) {
        return wsLinkPromise.then(() => subscriber(sink));
      }
      return wsLink.subscribe(operation, sink);
    });
  }
}
const lazyWsLink = new LazyWsLink();
const link = split(({query}) => {
  const main = getMainDefinition(query);
  return main.kind === "OperationDefinition" && main.operation === "subscription";
}, lazyWsLink, httpLink);
const cache = new InMemoryCache({
  typePolicies: {
    Query: {
      fields: {
        device(_, {args, toReference}) {
          return toReference({
            __typename: "Device",
            id: args?.id
          });
        }
      }
    }
  }
});
export const client = new ApolloClient({
  defaultOptions: {
    watchQuery: {
      fetchPolicy: "cache-and-network"
    }
  },
  link: ApolloLink.from([
    onError(({graphQLErrors, networkError}) => {
      if (graphQLErrors)
        graphQLErrors.map(({message, locations, path, ...rest}) => console.error(`[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`, rest));
      if (networkError)
        console.error(`[Network error]: ${networkError}`);
    }),
    link
  ]),
  cache
});
client.query({
  query: gql`
      query {
        server {
          buildHash
          installation
        }
      }
    `
}).then((data) => {
  if (data.data.server.buildHash && ({ url: getAbsoluteUrl('_dist_/lib/store.js'), env: __SNOWPACK_ENV__ }).env.SNOWPACK_PUBLIC_BUILD_HASH && data.data.server.buildHash !== ({ url: getAbsoluteUrl('_dist_/lib/store.js'), env: __SNOWPACK_ENV__ }).env.SNOWPACK_PUBLIC_BUILD_HASH) {
    window.location.reload();
  }
});
client.subscribe({
  query: gql`
      subscription deviceUpdated {
        deviceUpdated {
          id
          name
          config
        }
      }
    `
}).subscribe({});
client.subscribe({
  query: gql`
      subscription deviceStatusUpdated {
        deviceStatusUpdated {
          id
          value
        }
      }
    `
}).subscribe({});
