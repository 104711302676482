import {gql, useQuery} from "../../../../web_modules/@apollo/client.js";
import ButtonBase2 from "../../../../web_modules/@material-ui/core/ButtonBase.js";
import Switch2 from "../../../../web_modules/@material-ui/core/Switch.js";
import {pipeValue} from "../../../../web_modules/iterates.js";
import {defaultInterfaces} from "../../../../web_modules/raxa-common/lib/default-interfaces.js";
import React, {Suspense, memo, useState} from "../../../../web_modules/react.js";
import styled2 from "../../../../web_modules/@emotion/styled.js";
import {useUpdateDeviceStatus} from "../../../lib/mutations.js";
import {statusAsMap} from "../../../lib/object.js";
import {Option} from "../../../lib/types.js";
import {waitForIdle} from "../../ui/lazy-loading.js";
const LightingDialog = React.lazy(() => waitForIdle().then(() => import("../../ui/lighting-dialog.js")));
const ButtonBaseDiv = (props) => /* @__PURE__ */ React.createElement(ButtonBase2, {
  component: "div",
  ...props
});
const Container = styled2(ButtonBaseDiv)({
  "&&": {
    padding: 8
  },
  flexDirection: "column",
  boxSizing: "border-box",
  width: "100%",
  height: "100%",
  "& > div": {
    width: "100%"
  }
});
const NameRow = styled2("div")({
  display: "flex",
  alignItems: "center",
  height: 40
});
const DeviceName = styled2("span")({
  flexGrow: 1
});
const PowerSwitch = styled2(Switch2)({
  flexShrink: 0
});
const interfaceIds = [
  defaultInterfaces.Power.id,
  defaultInterfaces.Dimmer.id,
  defaultInterfaces.HueSat.id
];
const getDevice = gql`
  query($deviceId: String!, $interfaceIds: [String!]) {
    device(id: $deviceId) {
      id
      name
      interfaceIds
      status(interfaceIds: $interfaceIds) {
        id
        interfaceId
        statusId
        value
      }
    }
  }
`;
export const LightWidget = memo((props) => {
  const [showDetail, setShowDetail] = useState(false);
  const query = useQuery(getDevice, {
    skip: !props.deviceId,
    variables: {deviceId: props.deviceId, interfaceIds}
  });
  const device = query.data && query.data.device;
  const status = device && statusAsMap(device.status);
  const power = useUpdateDeviceStatus(pipeValue(status, Option.andThen((status2) => status2.get(defaultInterfaces.Power.id)), Option.andThen((power2) => power2.get(defaultInterfaces.Power.status.on.id)), Option.map((power2) => power2 === true || power2 === "true"), Option.unwrapOr(false)));
  return /* @__PURE__ */ React.createElement(Container, {
    onClick: () => setShowDetail(!showDetail)
  }, device && status && (device.interfaceIds.includes(defaultInterfaces.Dimmer.id) || device.interfaceIds.includes(defaultInterfaces.ColorTemperature.id) || device.interfaceIds.includes(defaultInterfaces.HueSat.id)) && /* @__PURE__ */ React.createElement(Suspense, {
    fallback: null
  }, /* @__PURE__ */ React.createElement(LightingDialog, {
    open: showDetail,
    onClose: () => setShowDetail(false),
    device,
    status
  })), device && /* @__PURE__ */ React.createElement(NameRow, null, /* @__PURE__ */ React.createElement(DeviceName, null, device.name), status ? /* @__PURE__ */ React.createElement(NameRow, {
    onMouseDown: (e) => e.stopPropagation(),
    onClick: (e) => e.stopPropagation()
  }, /* @__PURE__ */ React.createElement(PowerSwitch, {
    checked: power.displayValue,
    onChange: (_, value) => {
      power.commitUpdate({
        deviceId: device.id,
        interfaceId: defaultInterfaces.Power.id,
        statusId: defaultInterfaces.Power.status.on.id,
        value
      });
    }
  })) : /* @__PURE__ */ React.createElement("div", null)));
}, (prevProps, nextProps) => prevProps.deviceId !== nextProps.deviceId);
