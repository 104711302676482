import Button2 from "../../../web_modules/@material-ui/core/Button.js";
import Icon2 from "../../../web_modules/@material-ui/core/Icon.js";
import IconButton2 from "../../../web_modules/@material-ui/core/IconButton.js";
import ListItemIcon2 from "../../../web_modules/@material-ui/core/ListItemIcon.js";
import MoreVertIcon from "../../../web_modules/@material-ui/icons/MoreVert.js";
import React, {
  Suspense,
  lazy,
  useContext,
  useEffect,
  useRef,
  useState
} from "../../../web_modules/react.js";
import {useMedia} from "../../../web_modules/react-with-media.js";
import {row} from "../../../web_modules/style-definitions.js";
import {useIsMobile} from "./mediaQueries.js";
import {scaffoldContext} from "./scaffold.js";
const LazyTooltip = lazy(() => import("../../../web_modules/@material-ui/core/Tooltip.js"));
const LazyMenu = lazy(() => import("./menu.js").then((module) => ({default: module.Menu})));
const LazyMenuItem = lazy(() => import("./menu.js").then((module) => ({default: module.MenuItem})));
export const ContextActions = (props) => {
  const context = useContext(scaffoldContext);
  const ref = useRef(props.contextActions);
  useEffect(() => {
    context.setContextActions(props.contextActions);
    return () => context.clearContextActions();
  }, []);
  useEffect(() => {
    if (props.forceUpdate || ref.current !== props.contextActions && (ref.current.length !== props.contextActions.length || ref.current.some((a, i) => a.label !== props.contextActions[i].label || a.ariaLabel !== props.contextActions[i].ariaLabel || a.disabled !== props.contextActions[i].disabled || a.type !== props.contextActions[i].type || a.component !== props.contextActions[i].component || a.form !== props.contextActions[i].form))) {
      context.setContextActions(props.contextActions);
      ref.current = props.contextActions;
    }
  }, [context, props.contextActions, props.forceUpdate]);
  return null;
};
const ActionRow = (props) => /* @__PURE__ */ React.createElement("div", {
  ...props,
  style: {
    ...row({horizontal: "flex-end", vertical: "center"}),
    ...props.style
  }
});
const wrapWithTooltip = (tooltip, tooltipPlacement, children) => tooltip === void 0 ? children : typeof tooltip === "string" ? /* @__PURE__ */ React.createElement(Suspense, {
  fallback: children
}, /* @__PURE__ */ React.createElement(LazyTooltip, {
  key: children.key,
  title: tooltip,
  placement: tooltipPlacement
}, children)) : /* @__PURE__ */ React.createElement(Suspense, {
  fallback: children
}, /* @__PURE__ */ React.createElement(LazyTooltip, {
  key: children.key,
  placement: tooltipPlacement,
  ...tooltip
}, children));
export const Actions = (props) => {
  const menuRef = useRef(null);
  const [open, setOpen] = useState(false);
  const isMobile = useIsMobile();
  const isMouse = useMedia("(pointer: fine)");
  const {actions, color, tooltipPlacement, style} = props;
  const icons = [];
  const menuItems = [];
  const auto = [];
  for (let action of actions) {
    switch (action.placement) {
      case void 0:
      case "auto":
        auto.push(action);
        break;
      case "toolbar":
        icons.push(action);
        break;
      case "menu":
        menuItems.push(action);
        break;
    }
  }
  {
    let action;
    if (isMobile && icons.length === 0 && auto.length === 1) {
      icons.push(auto.shift());
    } else {
      while ((action = auto.shift()) !== void 0) {
        if (!isMobile && (icons.length < 2 || auto.length === 1 && icons.length === 2)) {
          icons.push(action);
        } else {
          menuItems.unshift(action);
        }
      }
    }
  }
  return /* @__PURE__ */ React.createElement(ActionRow, {
    style
  }, icons.map((item, i) => item.icon && !item.label ? wrapWithTooltip(item.tooltip, tooltipPlacement, /* @__PURE__ */ React.createElement(IconButton2, {
    key: item.label || i,
    component: item.component,
    "aria-label": item.ariaLabel,
    onClick: item.onClick,
    disabled: item.disabled,
    type: item.type,
    form: item.form,
    color,
    ...item.props
  }, item.icon)) : /* @__PURE__ */ React.createElement(Button2, {
    key: item.label || i,
    component: item.component,
    onClick: item.onClick,
    disabled: item.disabled,
    type: item.type,
    form: item.form,
    style: {minWidth: 0},
    color,
    size: isMouse ? "small" : "medium",
    "aria-label": item.ariaLabel,
    ...item.props
  }, item.icon && /* @__PURE__ */ React.createElement(ListItemIcon2, {
    style: {marginRight: 8, minWidth: 24}
  }, /* @__PURE__ */ React.createElement(Icon2, {
    children: item.icon
  })), item.label)), menuItems.length > 0 && [
    /* @__PURE__ */ React.createElement(IconButton2, {
      key: "button",
      ref: menuRef,
      "aria-label": "More",
      "aria-owns": open ? "action-menu" : void 0,
      "aria-haspopup": "true",
      onClick: () => setOpen(true),
      color,
      size: isMouse ? "small" : "medium"
    }, /* @__PURE__ */ React.createElement(MoreVertIcon, null)),
    /* @__PURE__ */ React.createElement(Suspense, {
      key: "menu",
      fallback: null
    }, /* @__PURE__ */ React.createElement(LazyMenu, {
      open,
      onClose: () => setOpen(false),
      anchorEl: menuRef.current,
      anchorOrigin: {
        vertical: "top",
        horizontal: "right"
      },
      transformOrigin: {
        vertical: "top",
        horizontal: "right"
      },
      MenuListProps: {
        dense: isMouse
      }
    }, menuItems.map((item, i) => /* @__PURE__ */ React.createElement(LazyMenuItem, {
      key: item.label || i,
      "aria-label": item.ariaLabel,
      dense: isMouse,
      onClick: (e) => {
        setOpen(false);
        if (item.onClick)
          return item.onClick(e);
      },
      disabled: item.disabled,
      type: item.type,
      form: item.form,
      ...{component: item.component},
      ...item.props
    }, item.icon && /* @__PURE__ */ React.createElement(ListItemIcon2, {
      style: {marginRight: 8, minWidth: 24}
    }, /* @__PURE__ */ React.createElement(Icon2, {
      children: item.icon
    })), " ", item.label || ""))))
  ]);
};
