import Button2 from "../../../web_modules/@material-ui/core/Button.js";
import React, {Suspense, lazy} from "../../../web_modules/react.js";
export const waitForIdle = () => new Promise((resolve) => requestAnimationFrame(() => requestAnimationFrame(() => requestIdleCallback(resolve))));
export const LazyColorPickerDialog = lazy(() => waitForIdle().then(() => import("./color-picker-dialog.js")).then((module) => ({default: module.ColorPickerDialog})));
export const LazyDialog = lazy(() => waitForIdle().then(() => import("../../../web_modules/@material-ui/core/Dialog.js")));
const LazyDragDropContextInner = lazy(() => waitForIdle().then(() => import("../../../web_modules/react-beautiful-dnd.js")).then((module) => ({default: module.DragDropContext})));
export const LazyDragDropContext = (props) => /* @__PURE__ */ React.createElement(Suspense, {
  fallback: /* @__PURE__ */ React.createElement(React.Fragment, null, props.children)
}, /* @__PURE__ */ React.createElement(LazyDragDropContextInner, {
  ...props
}));
const LazyDraggableInner = lazy(() => waitForIdle().then(() => import("../../../web_modules/react-beautiful-dnd.js")).then((module) => ({default: module.Draggable})));
export const LazyDraggable = (props) => /* @__PURE__ */ React.createElement(Suspense, {
  fallback: /* @__PURE__ */ React.createElement(React.Fragment, null, props.children({
    draggableProps: {},
    dragHandleProps: {}
  }, {isDragging: false, isDropAnimating: false}, void 0))
}, /* @__PURE__ */ React.createElement(LazyDraggableInner, {
  ...props
}));
const LazyDroppableInner = lazy(() => waitForIdle().then(() => import("../../../web_modules/react-beautiful-dnd.js")).then((module) => ({default: module.Droppable})));
export const LazyDroppable = (props) => /* @__PURE__ */ React.createElement(Suspense, {
  fallback: /* @__PURE__ */ React.createElement(React.Fragment, null, props.children({
    droppableProps: {}
  }, {isDraggingOver: false, isUsingPlaceholder: false}))
}, /* @__PURE__ */ React.createElement(LazyDroppableInner, {
  ...props
}));
export function reorder(list, startIndex, endIndex) {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);
  return result;
}
const LazyProgressButtonInner = lazy(() => waitForIdle().then(() => import("./ProgressButton.js").then((module) => ({
  default: module.ProgressButton
}))));
export const LazyProgressButton = (props) => /* @__PURE__ */ React.createElement(Suspense, {
  fallback: /* @__PURE__ */ React.createElement(Button2, {
    ...props
  })
}, /* @__PURE__ */ React.createElement(LazyProgressButtonInner, {
  ...props
}));
