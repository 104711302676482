import React, {
  createContext,
  useContext,
  useMemo,
  useState
} from "../../web_modules/react.js";
const snackbarContext = createContext(void 0);
export const SnackbarProvider = (props) => {
  const [state, setState] = useState({
    active: false,
    label: ""
  });
  const context = useMemo(() => ({
    ...state,
    showSnackbar: (snackbar) => setState({...snackbar, active: true}),
    hideSnackbar: () => setState({active: false, label: ""})
  }), [state]);
  return /* @__PURE__ */ React.createElement(snackbarContext.Provider, {
    value: context
  }, props.children);
};
export const useSnackbar = () => useContext(snackbarContext);
